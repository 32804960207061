/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */
$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "../../bower_components/bootstrap-sass/assets/fonts/bootstrap/";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/components-font-awesome/scss/fontawesome.scss";
// endbower

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

.auth_bg{
  background: url("../assets/images/background.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover!important;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #f5f5f5;
}

.table-wrap-normal > tbody > tr > td{
  white-space: normal!important;
}

.form-control{
  font-size: 12px !important;
}

.btn{
  font-size: 12px !important;
}


.ranges li{
  font-size: 12px !important;
}

.dropdown-menu{
  font-size: 12px !important;
}

body{
  font-size: 12px !important;
  min-height: 100vh !important;
  height: 100vh !important;
}

.main-header .sidebar-toggle {
  float: left;
  background-color: transparent;
  background-image: none;
  padding: 15px 15px;
  /* font-family: fontAwesome; */
  font-family: "Font Awesome\ 5 Free"!important /* cjr */;
}
.main-header .sidebar-toggle:before {
  content: "\f0c9"!important;
  font-weight: 900!important; /* cjr */
}

.form-fixed .select2{
  width: 100% !important;
  display: block;
}

.form-fixed label{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}

.select2 {
  display: block;
}

.skin-black-light .main-sidebar{
  background: #f9fafc !important;
}

.skin-black-light .wrapper, .skin-black-light .left-side
{
  background-color: #ecf0f5 !important;
}

.bg-danger {
  background-color: #eca4a4 !important;
  }

.input-group-btn select {
  border-radius: 0!important;
  -webkit-appearance: none;
    -moz-appearance: none;
}

.input-group-sm select {
  height: 30px!important;
}

.skin-black-light .sidebar-menu>li>a {
  font-weight: normal;
}

.skin-black-light .sidebar-menu>li.active {
  font-weight: 600!important;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "components/malarkey/malarkey.scss";
@import "components/navbar/navbar.scss";
// endinjector
